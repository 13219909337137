@use "./../variables/breakpoints" as *;

section {
	--section-space-y: 4.5rem;
	background-color: inherit;

	@include breakpoint-min-md {
		--section-space-y: 9.5rem;
	}

	margin-block: var(--section-space-y);

	&:first-child {
		margin-top: 0;
	}

	&[class|="bg"] {
		margin-block: 0;
		padding-block: var(--section-space-y);

		&:last-child {
			margin-bottom: 0;
		}
	}

	&[class|="bg-white"] {
		+section[class|="bg-white"] {
			padding-top: 0 !important;
		}
	}

	// da sistemare
	&.section-specific {
		margin-block: unset;

		&[class|="bg"] {
			padding-block: unset;
		}

		>.section-specified {
			padding-block: var(--section-space-y);
		}
	}

	&.parallax-bg {
		aspect-ratio: 16/6;
		margin-block: unset !important;

		video {
			width: 100%;
			height: 100%;

			object-fit: cover;
			object-position: center right;
		}

		picture {
			height: 100%;

			img {
				width: 100%;
				height: 100%;

				object-fit: cover;
				object-position: center;
			}
		}
	}

	// * Mynd Landing
	&.section--center {
		min-height: 100vh;
		margin-block: 0;

		position: relative;

		display: flex;
		flex-direction: column;
		justify-content: center;

		padding-top: 7rem;
		padding-bottom: 9rem;

		overflow-x: hidden;

		@include breakpoint-min-md {
			padding-bottom: 2rem;

			background-image: url('/img/hero-glow-1.png');
			background-position: 0% 50vh;
			background-repeat: no-repeat;
		}

		.section-content {}
	}

	.section--body {
		flex: 1;

		display: flex;
		flex-direction: column;
		justify-content: center;

		gap: 1.5rem;

		@include breakpoint-max-md {
			margin-top: -5rem;
		}

		@include breakpoint-min-md {
			gap: 3rem;
		}

		@include breakpoint-min-lg {
			gap: 4rem;
		}
	}

	&.big-spacing {
		@include breakpoint-min-md {
			--section-space-y: 6rem;
		}

		@include breakpoint-min-xl {
			--section-space-y: 11.875rem;
		}

	}
}

.drawing {
	position: relative;

	text-align: right;
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;

	img {
		max-width: 349px;
		width: 100%;
	}

	&:after {
		content: '';

		position: absolute;
		bottom: 0;

		width: 50vw;
		height: 1px;

		background-color: #000;
		left: 100%;

	}
}

.hero-grid {
	display: grid;
	grid-template-columns: 1fr 1fr;
	row-gap: 1rem;

	@include breakpoint-min-md {
		row-gap: 2rem;
	}

	@include breakpoint-min-md {
		grid-template-columns: 2fr 1fr;
		grid-template-rows: 1fr 2fr;
	}

	.badge {
		order: 1;
		margin-bottom: 0;

		justify-self: left;
		align-self: end;
	}

	.drawing {
		order: 2;

		@include breakpoint-min-md {
			grid-row-start: 1;
			grid-row-end: 3;

			grid-column-start: 2;
			grid-column-end: 3;
		}
	}

	.display-1 {
		grid-column-start: 1;
		grid-column-end: 3;
		order: 3;

		align-self: center;

		@include breakpoint-min-md {
			order: 2;
			grid-column-end: 2;

			margin-right: -10%;
		}

		@include breakpoint-min-md {
			margin-right: -20%;
		}
	}
}