@use './../variables/breakpoints' as *;

.vantages-section {
    background-image: url("/img/dark-bg-glow.svg");

    background-position: 10% 100%;
    background-repeat: no-repeat;
    background-size: cover;

    @include breakpoint-min-md {
        background-size: contain;
        background-position: 130% 100%;
    }
}