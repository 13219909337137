@use "./../variables/weight" as fw;
@use "./../variables/breakpoints" as *;
@use "./../variables/font-family" as *;

p {
	font-size: clamp(1rem, 0.9535714285714285rem + 0.19047619047619047vw, 1.125rem);
	line-height: clamp(1.25rem, 1.1803571428571429rem + 0.2857142857142857vw, 1.4375rem);
	font-weight: fw.$light;

	letter-spacing: -0.0125rem;
	margin-bottom: 1.625rem;

	&.paragraph-1 {
		font: {
			family: map-get($font-family, 2);
			size: clamp(1rem, 0.8142857142857143rem + 0.7619047619047619vw, 1.5rem);
		}

		letter-spacing: -0.0125rem;
		line-height: 1.2;
		// line-height: clamp(1.5rem, 1.2678571428571428rem + 0.9523809523809524vw, 2.125rem);

	}

	&.paragraph-2 {
		font-size: 1.25rem;
	}
}

.lead {
	font-size: 1.25rem;
}

.caption {
	&-1 {
		font-size: clamp(0.875rem, 0.8285714285714285rem + 0.19047619047619047vw, 1rem);
	}
}