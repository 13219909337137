@use './../custom/colors' as c;

$arrowCtaSize: 1rem;
$arrowCtaColor: map-get(c.$main-colors, primary);
$arrowBorderWidth: 3px;

.arrowCta {
    display: block;
    height: $arrowCtaSize;
    width: $arrowCtaSize;
    border: 0 solid transparent;
    transform: rotate(45deg);
    position: relative;
    margin: auto;

    &:after,
    &:before {
        content: "";
        display: block;
        height: inherit;
        width: inherit;
        position: absolute;
        top: 0;
        left: 0;
    }

    &:after {
        border-bottom: $arrowBorderWidth solid $arrowCtaColor;
        border-right: $arrowBorderWidth solid $arrowCtaColor;
        top: 0;
        left: 0;
        opacity: 1;
        animation: bottom-arrow 1.65s infinite;
    }

    &:before {
        top: 0;
        left: 0;
        border-bottom: $arrowBorderWidth solid $arrowCtaColor;
        border-right: $arrowBorderWidth solid $arrowCtaColor;
        animation: top-arrow 1.65s infinite;
    }
}

@keyframes bottom-arrow {
    0% {
        opacity: 1;
        transform: translate(0, 0);
    }

    45% {
        opacity: 0;
        transform: translate(12px, 12px);
    }

    46% {
        opacity: 0;
        transform: translate(-16px, -16px);
    }

    90% {
        opacity: 1;
        transform: translate(-6px, -6px);
    }

    100% {
        opacity: 1;
        transform: translate(-6px, -6px);
    }
}

@keyframes top-arrow {
    0% {
        transform: translate(-6px, -6px);
    }

    35% {
        transform: translate(0, 0);
    }

    90% {
        opacity: 1;
        transform: translate(0, 0);
    }

    100% {
        opacity: 1;
        transform: translate(0, 0);
    }
}