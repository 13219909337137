@use './../variables/breakpoints' as *;

.modal {
	--bs-modal-border-radius: 0;
	--bs-modal-inner-border-radius: 1.5rem;
	--bs-modal-header-border-width: 0;

	// --bs-modal-header-padding-x: calc(24px + 8px);
	// --bs-modal-header-padding-y: calc(24px + 8px);

	&-header {
		padding: {
			top: calc(24px + 8px);
			right: calc(24px + 8px);
		}
	}

	// modal--mail
	&--mail {
		.drawing-footer {
			display: none;

			position: absolute;
			bottom: 0;
			left: 0;



			@include breakpoint-min-lg {
				display: block;
				width: 156px;
			}

			@include breakpoint-min-xl {
				width: 216px;
			}
		}
	}

	// thank you modal
	&--thank-you {

		// min-height: 460px;
		@include breakpoint-min-xl {
			max-width: 1000px;
		}

		.modal-content {
			background-image: url("/img/dark-bg-glow.svg");
			background-repeat: no-repeat;

			background-position: 180% 100%;
			background-size: 60%;

			@include breakpoint-min-md {
				background-size: 50%;
				background-position: 150% 130%;
			}
		}

		.modal-body {
			display: flex;
			justify-content: center;

			max-height: 460px;
			height: 100vh;
		}

		.drawing-footer {
			@include breakpoint-min-lg {
				width: 266px;
				left: 2rem;
			}
		}
	}


}