@use './../variables/breakpoints' as *;

.section--mail {
    &:not(.no-bg) {
        background-image: url('/img/hero-glow-2.svg'), url('/img/hero-glow-3.svg');
        background-position: 40% 100%, 100% 100%;
        background-repeat: no-repeat;
    }

    position: relative;
    z-index: 0;
    overflow: hidden;

    --padding-block-container: 4.5rem;

    &-container {
        background-color: #fff;

        position: relative;

        padding-top: var(--padding-block-container);
        padding-bottom: var(--padding-block-container);

        >.col-12 {
            max-width: 600px;
        }
    }

    .drawing-footer {
        position: relative;

        img {
            width: 100%;
            height: auto;

            position: absolute;
            bottom: calc(-1 * var(--section-space-y));

            display: none;

            @include breakpoint-min-md {
                left: -5%;
                display: block;
                max-width: 160px;
            }

            @include breakpoint-min-lg {
                left: 0;
                max-width: 200px;
            }

            @include breakpoint-min-xl {
                max-width: 362px;
                left: -10%;
            }
        }
    }

    .mail-paper {
        display: none;

        position: absolute;
        right: 4%;
        top: 3rem;

        width: 150px;
        height: auto;

        @include breakpoint-min-md {
            display: block;
        }
    }

    &-small {
        position: absolute;
        width: 100%;
        left: 50%;
        transform: translateX(-50%);
        bottom: calc(var(--padding-block-container) / 3);

        @include breakpoint-min-md {
            text-align: center;
        }
    }
}