@use "./../variables/breakpoints" as *;
@use "./../variables/weight" as fw;
@use "./../custom/colors" as c;

$btn-font-family: "Overpass", sans-serif;
$btn-flex: true; // boolean
$btn-gap: 0.5rem;

$btn-main-colors: false; // boolean
$btn-outline-main-colors: false; // boolean

$btn-default-colors: false; // boolean
$btn-outline-default-colors: false; // boolean

$btn-icon-hover-rotate: false; // boolean
$btn-icon-collapse-rotate: false; // boolean
$btn-font-weight: null;
$btn-text-uppercase: true; // boolean
$btn-border-width: 0.0625rem;
$btn-border-radius: 9999px;

// btn outline
$btn-outline-border-width: $btn-border-width;
$btn-outline-border-radius: $btn-border-radius;

$btn-outline-hover-bg-color: map-get(c.$main-colors, primary);


.btn {
	@if $btn-flex {
		display: inline-flex;
		align-items: center;
		// gap: $btn-gap;
	}

	--bs-btn-font-family: #{$btn-font-family};
	--bs-btn-font-size: 1rem;

	--bs-btn-color: inherit;

	--bs-btn-box-shadow: unset;
	--bs-btn-focus-box-shadow: unset;

	--bs-btn-padding-top: 0.625rem;
	--bs-btn-padding-right: 1.375rem;
	--bs-btn-padding-bottom: 0.4375rem;
	--bs-btn-padding-left: 1.375rem;

	--bs-btn-border-radius: #{$btn-border-radius};
	--bs-btn-border-width: 0.0625rem;

	--bs-btn-font-weight: #{$btn-font-weight};

	--bs-btn-active-shadow: unset;

	--bs-btn-line-height: 1;

	&:not(.btn-action) {
		height: 2.5rem;
	}

	padding: var(--bs-btn-padding-top) var(--bs-btn-padding-right) var(--bs-btn-padding-bottom) var(--bs-btn-padding-left);

	@each $color,
	$value in c.$main-colors {

		@if $btn-main-colors {
			&-#{$color} {

				// status: default
				@if (lightness($value) > 75) {
					--bs-btn-color: var(--dark);
				}

				@else {
					--bs-btn-color: var(--white);
				}

				--bs-btn-bg: var(--#{$color});
				--bs-btn-border-color: var(--#{$color});

				// status: hover
				@if (lightness($value) > 75) {
					--bs-btn-hover-color: var(--dark);
				}

				@else {
					--bs-btn-hover-color: var(--white);
				}

				--bs-btn-hover-bg: var(--#{$color}-dark);
				--bs-btn-hover-border-color: var(--#{$color}-dark);

				// status: active
				@if (lightness($value) > 75) {
					--bs-btn-active-color: var(--dark);
				}

				@else {
					--bs-btn-active-color: var(--white);
				}

				--bs-btn-active-bg: var(--#{$color}-darken);
				--bs-btn-active-border-color: var(--#{$color}-darken);

				// disabled
				@if (lightness($value) > 75) {
					--bs-btn-disabled-color: var(--dark);
				}

				@else {
					--bs-btn-disabled-color: var(--white);
				}

				--bs-btn-disabled-bg: var(--#{$color});
				--bs-btn-disabled-border-color: var(--#{$color});
				--bs-btn-disabled-opacity: 0.45;
			}
		}

		@if $btn-outline-main-colors {

			// outline btn
			&-outline-#{$color} {
				// status: default
				--bs-btn-color: var(--#{$color});
				--bs-btn-bg: var(--white);
				--bs-btn-border-color: var(--#{$color});

				// status: hover
				--bs-btn-hover-color: var(--white);
				--bs-btn-hover-bg: var(--#{$color});
				--bs-btn-hover-border-color: var(--#{$color}-dark);

				// status: active
				--bs-btn-active-color: var(--white);
				--bs-btn-active-bg: var(--#{$color}-darken);
				--bs-btn-active-border-color: var(--#{$color}-darken);
			}
		}
	}

	@each $color,
	$value in c.$default-colors {
		@if $btn-default-colors {
			&-#{$color} {

				// status: default
				@if (lightness($value) > 75) {
					--bs-btn-color: var(--dark);
				}

				@else {
					--bs-btn-color: var(--white);
				}

				--bs-btn-bg: var(--#{$color});
				--bs-btn-border-color: var(--#{$color});

				// status: hover
				@if (lightness($value) > 75) {
					--bs-btn-hover-color: var(--dark);
				}

				@else {
					--bs-btn-hover-color: var(--white);
				}

				--bs-btn-hover-bg: var(--#{$color}-dark);
				--bs-btn-hover-border-color: var(--#{$color}-dark);

				// status: active
				@if (lightness($value) > 75) {
					--bs-btn-active-color: var(--dark);
				}

				@else {
					--bs-btn-active-color: var(--white);
				}

				--bs-btn-active-bg: var(--#{$color}-darken);
				--bs-btn-active-border-color: var(--#{$color}-darken);

				// disabled
				@if (lightness($value) > 75) {
					--bs-btn-disabled-color: var(--dark);
				}

				@else {
					--bs-btn-disabled-color: var(--white);
				}

				--bs-btn-disabled-bg: var(--#{$color});
				--bs-btn-disabled-border-color: var(--#{$color});
				--bs-btn-disabled-opacity: 0.45;
			}
		}

		@if $btn-outline-default-colors {
			&-outline-#{$color} {
				// status: default
				--bs-btn-color: var(--#{$color});
				--bs-btn-bg: var(--white);
				--bs-btn-border-color: var(--#{$color});

				// status: hover
				--bs-btn-hover-color: var(--white);
				--bs-btn-hover-bg: var(--#{$color});
				--bs-btn-hover-border-color: var(--#{$color}-dark);

				// status: active
				--bs-btn-active-color: var(--white);
				--bs-btn-active-bg: var(--#{$color}-darken);
				--bs-btn-active-border-color: var(--#{$color}-darken);
			}
		}
	}

	// icon
	.btn-icon {
		font-size: 0.6875rem;
		margin-left: -0.6875rem;

		transition: margin-left 0.2s ease-in-out, opacity 0.2s ease-in-out;
		opacity: 0;
	}

	&:hover {
		.btn-icon {
			margin-left: 0.3125rem;
			opacity: 1;
		}
	}

	&:not(.btn-action) {

		>[class|="material"],
		>[class|="icon"] {
			margin-left: -0.4rem;
			margin-right: -0.4rem;
			line-height: 0;
		}
	}

	@if $btn-text-uppercase {
		text-transform: uppercase;
	}

	// hover rotate
	@if $btn-icon-hover-rotate {
		>* {
			transition: rotate 0.2s ease;
		}

		&:hover {
			>[class|="material"] {
				rotate: 180deg;
			}
		}
	}

	&-gradient {
		position: relative;

		&:before,
		&:after {
			content: "";

			position: absolute;
			top: 0;
			left: 0;

			z-index: -1;

			width: 100%;
			height: 100%;

			transition: opacity 0.2s ease-in-out;

			border-radius: inherit;
		}

		&:before {
			background-color: var(--primary);
		}

		&:after {
			background: linear-gradient(134deg,
				#{map-get(c.$main-colors, primary)} 0%,
				#1a1a85 100%);
		}

		// status: default
		--bs-btn-color: var(--white);
		background: unset !important;

		// status: hover
		--bs-btn-hover-color: var(--white);

		&:hover {
			&::after {
				opacity: 0;
			}
		}

		// status: active
		--bs-btn-active-color: var(--white);

		&:active {
			&::before {
				background-color: var(--primary-darken);
			}
		}
	}

	&-link {
		text-decoration: underline;
		text-decoration-thickness: 0.0625rem;
		letter-spacing: -0.0313rem;
		text-underline-offset: 0.125rem;

		--bs-btn-color: inherit;

		--bs-btn-bg: transparent;
		--bs-btn-hover-bg: transparent;
		--bs-btn-active-bg: transparent;

		--bs-btn-padding-top: 0;
		--bs-btn-padding-bottom: 0;
		--bs-btn-padding-left: 0;
		--bs-btn-padding-right: 0;

		--bs-btn-font-weight: #{fw.$bold};

		@each $color,
		$value in c.$main-colors {
			&.btn-#{$color} {
				--bs-btn-color: var(--#{$color});
				--bs-btn-hover-color: var(--#{$color}-darken);
				--bs-btn-active-color: var(--#{$color}-darken);
			}
		}
	}

	// btn action
	&-action {
		--bs-btn-border-radius: 9999px;
		--bs-btn-disabled-opacity: 0.34;
		display: inline-grid;
		place-items: center;

		font-size: 1.5rem;
		padding: 0.6875rem;

		&-white {
			// status: default
			--bs-btn-color: var(--black);
			--bs-btn-bg: var(--white);
			--bs-btn-border-color: var(--white);

			// status: hover
			--bs-btn-hover-color: var(--white);
			--bs-btn-hover-bg: var(--primary);
			--bs-btn-hover-border-color: var(--primary);

			// status: active
			--bs-btn-active-color: var(--white);
			--bs-btn-active-bg: var(--primary-dark);
			--bs-btn-active-border-color: var(--primary-dark);
		}


	}

	// btn small
	&-sm {
		--bs-btn-font-size: 0.875rem;

		--bs-btn-font-weight: #{fw.$bold};

		--bs-btn-padding-top: 0.25rem;
		--bs-btn-padding-bottom: 0.3125rem;
		--bs-btn-padding-left: 0.5625rem;
		--bs-btn-padding-right: 0.5625rem;

		--bs-btn-line-height: 1.0625rem;
	}

	&-md {
		--bs-btn-padding-top: 0.8438rem;
		--bs-btn-padding-bottom: 0.8438rem;
		--bs-btn-padding-left: 1rem;
		--bs-btn-padding-right: 1rem;
	}

	&-full {
		width: 100%;
	}

	&[aria-expanded] {
		transition: transform 0.2s ease-in-out;
	}

	// collapse rotate
	@if($btn-icon-collapse-rotate) {
		&[aria-expanded="false"] {
			transform: rotate(180deg);
		}
	}

	&-outline-dark {
		// status: default
		--bs-btn-color: var(--black);
		--bs-btn-bg: transparent;
		--bs-btn-border-color: var(--black);

		// status: hover
		--bs-btn-hover-color: var(--white);
		--bs-btn-hover-bg: var(--primary);
		--bs-btn-hover-border-color: var(--primary);

		// status: active
		--bs-btn-active-color: var(--white);
		--bs-btn-active-bg: var(--primary-dark);
		--bs-btn-active-border-color: var(--primary-dark);
	}

	&-outline-white {
		// status: default
		--bs-btn-color: var(--white);
		--bs-btn-bg: transparent;
		--bs-btn-border-color: var(--white);

		// status: hover
		--bs-btn-hover-color: var(--white);
		--bs-btn-hover-bg: var(--primary);
		--bs-btn-hover-border-color: var(--primary);

		// status: active
		--bs-btn-active-color: var(--white);
		--bs-btn-active-bg: var(--primary-dark);
		--bs-btn-active-border-color: var(--primary-dark);

		&.btn-hover-white {
			// status: hover
			--bs-btn-hover-color: var(--primary);
			--bs-btn-hover-bg: var(--white);
			--bs-btn-hover-border-color: var(--primary);

			// status: active
			--bs-btn-active-color: var(--primary-dark);
			--bs-btn-active-bg: transparent;
			--bs-btn-active-border-color: var(--primary-dark);
		}
	}
}

.btn-inverted {
	.btn {
		&-outline {
			// status: default
			--bs-btn-color: var(--white);
			--bs-btn-bg: transparent;
			--bs-btn-border-color: var(--white);

			// status: hover
			--bs-btn-hover-color: var(--white);
			--bs-btn-hover-bg: var(--primary);
			--bs-btn-hover-border-color: var(--primary);

			// status: active
			--bs-btn-active-color: var(--white);
			--bs-btn-active-bg: var(--primary-dark);
			--bs-btn-active-border-color: var(--primary-dark);
		}
	}

	&.active {
		.btn {
			&-outline {
				// status: default
				--bs-btn-color: var(--black);
				--bs-btn-bg: transparent;
				--bs-btn-border-color: var(--black);
			}
		}
	}
}

.btn-primary {
	--bs-btn-color: var(--white);
	--bs-btn-bg: var(--primary);
	--bs-btn-border-color: var(--primary);

	--bs-btn-hover-color: var(--black);
	--bs-btn-hover-bg: var(--white);
	--bs-btn-hover-border-color: var(--black);

	--bs-btn-active-color: var(--white);
	--bs-btn-active-bg: var(--primary-dark);
	--bs-btn-active-border-color: var(--primary-dark);

	--bs-btn-active-color: var(--white);
	--bs-btn-active-bg: var(--primary-dark);
	--bs-btn-active-border-color: var(--primary-dark);

	--bs-btn-disabled-opacity: 1;
	--bs-btn-disabled-bg: #d7d7d8;
	--bs-btn-disabled-color: var(--white);
	--bs-btn-disabled-border-color: #d7d7d8;
}