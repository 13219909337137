#sib-container {
    padding: 0;
    background: unset;
    margin: unset;
}

.sib-form {
    background-attachment: unset;
    font-size: unset;
    font-family: unset;
    padding: unset;
    margin: unset;
}

.sib-form-block {
    padding: 0;
}

.sib-form .entry__field {
    margin: 0;
    align-items: unset;
    background: unset;
    border: unset;
    border-radius: unset;
    display: unset;
    display: unset;
    margin: unset;
    max-width: unset;
}

.sib-form .entry__error,
.sib-form .entry__label,
.sib-form .entry__specification,
.sib-form .form__error {
    max-width: unset;
}

.sib-form .entry__label {
    display: unset;
    font-weight: unset;
    padding: unset;
    white-space: unset;
}

.sib-form .input {
    min-width: unset;
}

.sib-form .input:first-child,
.sib-form .input__affix:first-child {
    padding-left: unset;
}



.sib-form .input,
.sib-form .input__affix {
    background: unset;
    border: unset;
    font: unset;
    margin: unset;
}

.form__label-row {
    display: unset;
}

.sib-form .input,
.sib-form .input__affix {
    border-bottom: 1px solid rgba(#303030, 30%);
}

.sib-form .input:not(textarea),
.sib-form .input__button {
    height: 2.5rem;
}

.sib-form * {
    box-sizing: inherit !important;
}

.sib-form .checkbox {
    border-radius: 0;
}

.entry__choice {
    margin-bottom: 0;
}

.sib-form .input_replaced:active+.checkbox,
.sib-form .input_replaced:active+.radio-button,
.sib-form .input_replaced:focus+.checkbox,
.sib-form .input_replaced:focus+.radio-button {
    box-shadow: unset;
}

.sib-form .input_replaced+.checkbox_tick_positive {
    width: 24px;
    height: 24px;
    margin-right: 0;
    margin-top: 0 !important;
}

.sib-form .input_replaced:checked+.checkbox_tick_positive {
    background-color: #000;
    border-color: #000;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}

.sib-form .checkbox_tick_positive:before {
    content: unset;
}

.entry__choice label p {
    font-size: 0.875rem;
    font-weight: 300;
}

.sib-form .entry__choice {
    padding-left: 34px;
    text-indent: -34px;
}

.sib-optin .form__label-row+.entry__error.entry__error--primary {
    margin-top: 0;
    margin-bottom: 0;
}

.sib-form .entry__field:focus-within {
    box-shadow: unset;
}