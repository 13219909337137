@use './../variables/breakpoints' as *;

@include breakpoint-max-md {
    .btn-fixed-mobile {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;

        padding: 1rem;
        padding-bottom: 3rem;

        display: flex;
        justify-content: center;

        z-index: 5;

        background-color: #fff;

        box-shadow: 0 -6px 25px 0px rgba(0, 0, 0, .20);

        transform: translateY(100%);
        opacity: 0;

        transition: transform 0.5s ease-in-out, opacity 0.2s ease-in-out;

        &.active {
            transform: translateY(0);
            opacity: 1;
        }
    }
}